import { OutboundLink } from 'gatsby-plugin-google-gtag'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import './styles.scss'
import Form from '../VtexdayForm/Form'

const VtexdayVideo: React.FC = () => {
  return (
    <section className="container">
      <div className="row justify-content-md-center">
        <div className="col-md-5 px-md-layout-5">
          <h3 className=" text-primary text-center p-layout-4 somos-title_video"> 
            <FormattedMessage id="somos.video.title" />
          </h3>
          <p className="somos-text_video">
            <FormattedMessage id="somos.video.text" />
          </p>
        </div>
        <div className="col-md-7 py-layout-3 ">
          <h3 className="text-dark-gray-1 text-center"><FormattedMessage id="campaign.sign_up" /></h3>
          <Form />
        </div>
      </div>
      <div className="row pb-layout-4">
        <div className="col-12">
          <h3 className="somos-subtitle_video text-center">
            <FormattedMessage id="somos.video.subtitle" />
          </h3>
        <iframe className="campaign-video"
         src="https://www.youtube.com/embed/f4WANvbMyuE"
         title="YouTube video player"
         frameborder="0"
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
         allowfullscreen></iframe>
        </div>
      </div>
    </section>
  )
}

export default VtexdayVideo