import React from 'react'
import IndexLayout from '../layouts'
import { LocaleTypes } from '../data/locales';
import VtexdayHero from '../components/Vtexday/VtexdayHero/VtexdayHero';
import VtexdayCountries from '../components/Vtexday/VtexdayCountries/VtexdayCountries';
import VtexdayVideo from '../components/Vtexday/VtexdayVideo/VtexdayVideo';

interface VtexDayProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const VTEXDay: React.FC<VtexDayProps> = ({pathContext, location}) => {
  const content = () => (
    <>
      <VtexdayHero />
      
      <VtexdayVideo />

      <VtexdayCountries />
    </>
  )
  return (
    <IndexLayout locale={pathContext.localeCode} location={location} render={content} />
  )
}

export default VTEXDay